import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import jwtDecode from 'jwt-decode';

import { getAccessToken, setAccessToken } from './accessToken';
import { GRAPHQL_URL, ACCOUNTS_GRAPHQL_URL, REFRESH_URL_ABSOLUTE } from '../../config';

const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }

  try {
    const { exp } = jwtDecode(token);
    const now = Date.now();
    // console.info(now, exp * 1000, new Date(now), new Date(exp * 1000));
    return now >= exp * 1000;
  } catch {
    return true;
  }
};

const apolloClient = (serverAccessToken) => {
  const ssrMode = typeof window === 'undefined';

  const authMiddleware = new ApolloLink((operation, forward) => {
    const accessToken = getAccessToken() || serverAccessToken;
    if (accessToken) {
      operation.setContext({
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      });
    }
    return forward(operation);
  });

  const refreshOptions = {
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: () => {
      const token = getAccessToken();
      // console.info('Client: is token valid', token);
      return token === undefined || !isTokenExpired(token);
    },
    fetchAccessToken: () =>
      // console.info('fetchAccessToken', REFRESH_URL_ABSOLUTE);
      fetch(REFRESH_URL_ABSOLUTE, {
        credentials: 'include',
        method: 'POST',
      }),
    handleFetch: (accessToken) => {
      // console.info('handleFetch', accessToken);
      setAccessToken(accessToken);
    },
    handleError: (err) => {
      console.warn('Your refresh token is invalid. Try to login');
      console.error(err);
      setAccessToken('');
    },
  };

  const tokenRefreshLink = new TokenRefreshLink(refreshOptions);

  const eventsLink = createUploadLink({
    uri: GRAPHQL_URL,
  });

  const accountsLink = new HttpLink({
    uri: ACCOUNTS_GRAPHQL_URL,
    credentials: 'include',
  });

  const client = new ApolloClient({
    ssrMode,
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === 'accounts',
      accountsLink,
      ApolloLink.from([
        tokenRefreshLink,
        authMiddleware,
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            console.warn(graphQLErrors);
            graphQLErrors.forEach(({ message }) => console.warn(`[GraphQL error]: ${message}`));
          }
          if (networkError) console.warn(`[Network error]: ${networkError}`);
        }),
        eventsLink,
      ]),
    ),
    cache: new InMemoryCache({}),
  });
  return client;
};
export default apolloClient;
