/* eslint-disable react/prop-types */
import { ApolloProvider } from '@apollo/client';
import { resetServerContext } from 'react-beautiful-dnd';
import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';
import getConfig from 'next/config';
import Head from 'next/head';

import { useApollo } from '../utils/graphql/useApollo';
import '../scss/global.scss';
import '../admin/InputDateTime/datepicker.css';
import '../admin/TextEditor/editor.css';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          // eslint-disable-next-line no-param-reassign
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

// This default export is required in a new `pages/_app.js` file.
const App = ({ Component, pageProps, err }) => {
  const { serverAccessToken } = pageProps;
  const apolloClient = useApollo(pageProps.initialApolloState, serverAccessToken);

  resetServerContext();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <ApolloProvider client={apolloClient}>
        <Component {...pageProps} err={err} />
      </ApolloProvider>
    </>
  );
};
export default App;
