const dev = process.env.NODE_ENV !== 'production';
const { NEXT_PUBLIC_ACCOUNTS_SITE_URL: accountURL } = process.env;

export const ACCOUNTS_GRAPHQL_URL = process.env.NEXT_PUBLIC_ACCOUNTS_GRAPHQL_URL;
export const API_CDN = process.env.NEXT_PUBLIC_API_CDN;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
export const GRAPHQL_URL = process.env.NEXT_PUBLIC_GRAPHQL_URL;
export const IMG_SERVICE_URL = process.env.NEXT_PUBLIC_IMG_SERVICE_URL;
export const REFRESH_URL_ABSOLUTE = `${process.env.NEXT_PUBLIC_ACCOUNTS_SITE_URL}auth/refresh-token`;
export const S3_BUCKET = process.env.NEXT_PUBLIC_S3_BUCKET;
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;

const COOKIE_PREFIX = process.env.NEXT_PUBLIC_COOKIE_PREFIX;
export const COOKIES = {
  JWT: `${COOKIE_PREFIX}-jwt`,
  REDIRECT: `${COOKIE_PREFIX}-redirect`,
  REFRESH: `${COOKIE_PREFIX}-refresh`,
  RSVP: 'ovevents-do-rsvp',
};

export const DEFAULT_AVATAR = {
  alt: 'Avatar',
  src: `${API_CDN}users/images/dance.png`,
  width: 702,
  height: 702,
};
export const DJANGO_HEADERS = {
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
};
export const EVENT_ALERTS = {
  BUMMER: 'BUMMER',
  OH_NO: 'OH_NO',
  SIGN_IN: 'SIGN_IN',
};

export const APP_ID = '1497334859'; // unique ID in apple app-store.
export const FOCUSABLE_ELEMENTS = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
export const GOOGLE_API_KEY = 'AIzaSyAt4sO--DgwjhQNR2fDBXs6kIflD7WuzIY';
export const IMAGE_TREATMENTS = ['CIRCLE', 'ROUNDED_BOTTOM', 'ROUNDED_TOP', 'SQUARE'];
export const IP_INFO_KEY = dev ? '79b9cd017e56c1' : '9e5f55997f0128';
export const KEY_CODES = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  ENTER: 'Enter',
  ESC: 'Escape',
  TAB: 'Tab',
};
export const KEY_DOWN = 'keydown';
export const PAGE_TITLE = 'Outdoor Voices Events';
export const RESIZE = 'resize';
export const SCROLL = 'scroll';
export const SUPPORT_EMAIL = 'app-help@outdoorvoices.com';

// The Event Settings
export const EVENT_STATUSES = {
  CANCELLED: 'CANCELLED',
  POSTPONED: 'POSTPONED',
  SCHEDULED: 'SCHEDULED',
};

// The RSVP Settings
export const RSVP_STATUSES = {
  CANCELLED: 'CANCELLED',
  CONFIRMED: 'CONFIRMED',
  WAITLISTED: 'WAITLISTED',
};
export const RSVP_TYPES = {
  EXTERNAL: 'EXTERNAL',
  LIMITED: 'LIMITED',
  NONE: 'NONE',
  UNLIMITED: 'UNLIMITED',
};

// TODO: Make these images use the CDN since this is only stage'es cdn
export const ACCOUNT_IMAGE = {
  alt: 'Login or Sign in to your account',
  src: `${API_CDN}users/images/account.jpg`,
  width: 1495,
  sizes: [
    { size: 1495, minWidth: 1001 },
    { size: 1000, minWidth: 801 },
    { size: 600, minWidth: 401 },
    { size: 400, minWidth: 0 },
  ],
};

export const BANNER_IMAGE = {
  alt: '#DoingThings Events - Outdoor Voices',
  src: `${API_CDN}events/images/corona.png`,
  mobile: `${API_CDN}events/images/_Dog_Jog_Color.png`,
};

export const CORONA_IMAGE = {
  alt: 'Canceled',
  src: `${API_CDN}events/images/corona.png`,
  sizes: [
    { size: 332, minWidth: 0 },
    { size: 540, minWidth: 333 },
  ],
  width: 2470,
};

export const SUBSCRIBE_IMAGE = {
  alt: 'Subscribe to the #DoingThings Events Newsletter',
  src: `${API_CDN}events/images/subscribe.jpg`,
  sizes: [{ size: 432, minWidth: 0 }],
  width: 666,
};

// Break points
export const BREAK_MOBILE = 480;
export const BREAK_MOBILE_MEDIUM = 666;
export const BREAK_TABLET_SMALL = 890;
export const BREAK_TWO_COLUMN = 960;

// URLs
export const ACCOUNT_URLS = {
  FORGOT_PASSWORD_UN_AUTH: `${accountURL}users/forgot-password`,
  FORGOT_PASSWORD: `${accountURL}users/forgot-password`,
  GOOGLE_LOGIN: `${accountURL}auth/google/login`,
  HELP_SUPPORT: `/account/support`,
  LOGIN: `${accountURL}users/login`,
  LOGOUT: `${accountURL}users/logout`,
  MY_ACCOUNT: accountURL,
  PASSWORD: `${accountURL}users/reset-password`,
  PROFILE: accountURL,
  SETTINGS: `${accountURL}account/settings`,
  SIGN_UP: `${accountURL}users/register`,
  VERIFICATION: `${accountURL}registration/verify-account`,
};
export const ADMIN_URLS = {
  EVENTS_HUB_URL: '/coach/events',
  NEW_EVENT_URL: '/coach/events/new',
};
export const APPSTORE_URL = 'https://apps.apple.com/us/app/outdoor-voices/id1497334859';
export const EVENTS_URL = 'https://events.outdoorvoices.com';
export const OV_URL = 'https://www.outdoorvoices.com/';
export const NEW_ARRIVALS_URL = `https://${OV_URL}collections/new-arrivals`;
export const OV_HELP_URL = `${OV_URL}pages/contact-us`;
export const OV_TERMS_URL = `${OV_URL}terms`;
