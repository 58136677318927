import cookie from 'js-cookie';

import { COOKIES, COOKIE_DOMAIN } from '../../config';

export const getAccessToken = () => cookie.get(COOKIES.JWT);

export const setAccessToken = (accessToken) => {
  const opts = { expires: 7, domain: COOKIE_DOMAIN };
  if (accessToken === '') {
    cookie.remove(COOKIES.JWT, opts);
    return;
  }
  cookie.set(COOKIES.JWT, accessToken, { ...opts, secure: process.env.NODE_ENV === 'production' });
};
